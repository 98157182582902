/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import "~ng-pick-datetime/assets/style/picker.min.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import '~froala-editor/css/froala_editor.pkgd.min.css';
@import '~froala-editor/css/froala_style.min.css';
@font-face {
  font-family: "Montserrat";
  src: url("assets/font/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat-semi-bold";
  src: url("assets/font/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-bold";
  src: url("assets/font/Montserrat-Bold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-extra-bold";
  src: url("assets/font/Montserrat-ExtraBold.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "NeueHaasDisplayBold";
  src: url("assets/font/NeueHaasDisplayBold.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "NeueHaasDisplayLight";
  src: url("assets/font/NeueHaasDisplayLight.ttf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Montserrat-extra-Medium";
  src: url("assets/font/NeueHaasDisplayMedium.ttf") format("truetype");
  font-style: normal;
}

:root {
  --ion-color-primary: #083a81; /* Override the primary color */
}

.ql-editor {
  background: white !important;
}

ion-content {
  scrollbar-color: #083a81 #fff;
  scrollbar-width: thin;
  overflow-y: auto !important;
  --ion-background-color: linear-gradient(to right,
      #c3d9f6,
      #c8dcf6,
      #cee0f6,
      #d3e3f6,
      #d9e6f6,
      #dce7f6,
      #dee8f6,
      #e1e9f6,
      #e2e9f6,
      #e2e8f6,
      #e3e8f6,
      #e4e7f6);
}

ion-header {
  background: linear-gradient(to right,
      #c3d9f6,
      #c8dcf6,
      #cee0f6,
      #d3e3f6,
      #d9e6f6,
      #dce7f6,
      #dee8f6,
      #e1e9f6,
      #e2e9f6,
      #e2e8f6,
      #e3e8f6,
      #e4e7f6);
}

ion-modal {
  backdrop-filter: blur(12px);
}

.inp-container {
  height: 82px;
  padding: 0px 10px;

  ion-input {
    font-size: 12px;
    font-family: Montserrat;
    letter-spacing: 0px;
  }

  ion-text {
    padding-left: 6px;
  }

  ion-label {
    padding-left: 6px;
    display: block;
    margin-bottom: 6px;
    text-align: left;
    font-family: Montserrat;
    letter-spacing: 0px;
    color: #083a81;
    opacity: 1;
    font-size: 12px;
    font-weight: bold;
  }

  .read_label {
    font-size: var(--font-size-12);
    color: black;
    font-weight: bold;
  }

  ion-item {
    width: 80%;
    --background: rgba(234, 245, 251, 0.4);
    border: 1.5px white solid;
    border-radius: 10px;
    box-shadow: 0px 2px 10px 0px #d1d0d0de;
    backdrop-filter: blur(13px);
    --min-height: 35px;
  }
}

ng-select {
  width: 80%;
  .ng-input {
    height: 80%;

    input {
      height: 100%;
      caret-color: transparent;
    }
  }

  .ng-select-container {
    background-color: rgba(234, 245, 251, 0.4) !important;
    border: 1.5px #ffffff solid !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 10px 0px #d1d0d0de;
    --ion-item-background: transparent;
    -webkit-backdrop-filter: blur(13px);
    backdrop-filter: blur(13px);
    --min-height: 38px;
    height: 38px !important;
    font-size: 12px;
    letter-spacing: 0.5px;
    // font-weight: bold;
  }

  .ng-placeholder {
    font-weight: normal;
    font-family: "Montserrat";
  }
  .ng-dropdown-panel {
    scrollbar-width: thin;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items {
    display: block;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 137px !important;
}
  ng-dropdown-panel {
    
    box-shadow: none !important;
    width: 80%;
    background: transparent !important;
    padding-top: 2px !important;
    box-sizing: border-box;
    position: absolute;
    opacity: 0;
    width: 100%;
    z-index: 1050;
    overflow: hidden !important;
  }

    .ng-option {
      background-color: var(--cloudy-azure) !important;
      backdrop-filter: blur(13px) !important;
      border-bottom: 1.5px solid #ffffff;
      border-left: 1.5px solid #ffffff;
      border-right: 1.5px solid #ffffff;
      padding: 10px !important;
      max-height: 500px;
    }

    .ng-option:first-child {
      border-radius: 12px 12px 0px 0px !important;
    }

    .ng-option:last-child {
      border-radius: 0px 0px 12px 12px !important;
    }

    .ng-option-label {
      float: left !important;
    }
    .scroll-host{
    scrollbar-width: thin;
    } 
  }
 

.ng-select-disabled {
  .ng-select-container {
    opacity: 0.5;
    box-shadow: none !important;
  }
}

.w-100 {
  width: 100%;
}

.my-custom-toast {
  --background: #cfece2;
  --border-color: #1db93d;
  --border-radius: 13px;
  --border-style: solid;
  --border-width: 2px;
  --color: #00b429;
  text-align: center;
  // width: 416px;
  // height: 5.6em;
  // margin-left: 28%;
  margin-top: 4em;
  border-radius: 10px;
}

.scrollVertical {
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}

.scrollVertical::-webkit-scrollbar {
  height: 8px;
  width: 10px !important;
  padding: 20px;
  scrollbar-width: thin;
}

.scrollVertical::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c7cbcf;
  border-radius: 10px;
  scrollbar-width: thin;
}

.scrollVertical::-webkit-scrollbar-thumb {
  background: #083a81;
  border: 1px solid transparent;
  border-radius: 9px;
  scrollbar-width: thin;
}

.scrollHorizontal {
  // height: 30em;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: thin;
}

.scrollHorizontal::-webkit-scrollbar {
  height: 8px;
  /* height of horizontal scrollbar ← You're missing this */
  //
  // border: 1px solid red;
  padding: 20px;
  scrollbar-width: thin;
}

.scrollHorizontal::-webkit-scrollbar-thumb {
  background: #083a81;
  border: 1px solid transparent;
  border-radius: 9px;
  scrollbar-width: thin;


}

.scrollHorizontal ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c7cbcf;
  border-radius: 10px;
  scrollbar-width: thin;
}

.sticky-header {
  background-image: linear-gradient(to right,
      #d9e8f9,
      #d9e8f9,
      #d9e8f9,
      #d9e8f9,
      #dfebf7,
      #dfebf7,
      #dfebf7,
      #e6edf8,
      #e6edf8,
      #e6edf8,
      #e6edf8,
      #e6edf8);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}


.popover-position {
  left: 200 !important;
  top: 0 !important;
}

ion-popover {
  --width: 200px !important;
  left: 0 !important;
  top: 0 !important;
}

.popover-wide .alert-wrapper {
  margin-top: 50px;
  border-radius: 10px;
  border: 1px solid transparent;
  --background: rgba(234, 245, 251, 1) !important;
}
.status-button {
  width: 80px;
  border: none;
  color: var(--black);
  padding: 5px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: var(--font-size-10);
  border-radius: 10px;
  font-family: var(--font-family-semi-bold);
}

.app-btn {
  float: right;
  width: 120px;
  --border-color: var(--p-800-midnight-blue);
  color: var(--p-800-midnight-blue);
}

.btn-border {
  --border-color: var(--p-800-midnight-blue);
  color: var(--p-800-midnight-blue);
}

.app-large-btn {
  float: right;
  width: 180px;
}

.app-btn-margin {
  margin-right: 12px;
}

.log-edit-img {
  width: 16px;
  margin-bottom: -4px;
  margin-right: 8px;
}

.ellipsis-icon {
  float: right;
  margin-top: 8px;
  cursor: pointer;
}

.dct-extra-pad {
  width: 100%;
  padding-top: 6px;
  height: 65px;
  padding-bottom: 12px;
  padding-left: 14px;
}

.grid-block{
  height: 50px;
  padding: 0px 12px;
}

.extra-title-top {
  position: relative;
  top: 12px;
}
.ng-tooltip {
  position: absolute;
  max-width: 150px;
  font-size: 14px;
  text-align: center;
  color: var(--white);
  padding: 3px 8px;
  background: var(--p-800-midnight-blue);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
}
.ng-tooltip-show {
  opacity: 1;
}
.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}
.ng-tooltip-top:after {
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: var(--p-800-midnight-blue) transparent transparent transparent;
}
.ng-tooltip-right:after {
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent var(--p-800-midnight-blue) transparent transparent;
}
.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-color: transparent transparent var(--p-800-midnight-blue) transparent;
}
.ng-tooltip-left:after {
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-color: transparent transparent transparent var(--p-800-midnight-blue);
}
// Below three classes are used to replace row-col on dashboard, project, offer, order and machine mgmt page
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-column {
  display: flex;
}

.right-column {
  display: flex;
}
.search-icon-right-margin {
  margin-right: 16px;
}
.ellipsis-right-margin {
  margin-right: 16px;
}
// To remove default tooltip on ion-icon that was displayed using title on svg
ion-icon:before {
  content: '';
  position: absolute;
    width: 100%;
    height: 100%;
}
.card-transition {
  transition: tranform 1200ms;
}
.card-transition:hover {
  transform: scale(1.05)
}
.delete-icon-transition {
  transition: tranform 1200ms;
}
.delete-icon-transition:hover {
  transform: scale(1.1)
}
.exit-left-margin-12 {
  margin-left: 12px;
}
.exit-left-margin-110 {
  margin-left: 110px;
}
.exit-left-margin-120 {
  margin-left: 120px;
}
.exit-left-margin-140 {
  margin-left: 140px;
}
ion-popover {
  z-index: none !important;
  top: none !important;
}
.margin-right-18 {
  margin-right: 18px;
}
.hover-link {
  text-decoration: none;
  cursor: pointer;
  color: var(--p-800-midnight-blue);
}
.hover-link:hover {
  text-decoration: underline;
  color: var(--p-800-midnight-blue);
}

.visible {
  visibility: visible;
}

.hidden {
  visibility: hidden;
}

.grid-column-value
{
  text-align:left;
  color:#606060;
  font-size: var(--font-size-11);
  font-family: var(--font-family-semi-bold);
}

.grid-hover-link {
  text-decoration: none;
  cursor: pointer;
  color: var(--p-800-midnight-blue);
}

.grid-text-color {
  text-decoration: none;
  color: var(--p-800-midnight-blue);
}

.scroll-table {
    td {
      width: 10%;
      padding: 10px;
      font-size: var(--font-size-10);
      font-family: var(--font-family-semi-bold);
      text-align: left;
      letter-spacing: 0.3px;
      border-bottom: 1px solid var(--light-gray);
      background: var(--g-200-pale-blue);
    }
     th {
      width: 118px;
      height: 56px;
      text-align: left;
      min-width: 150px;
      padding: 10px;
      color: var(--black);
      font-size: 11px;
      font-family: var(--font-family-semi-semi-bold);
      letter-spacing: 0.3px;
      background: var(--g-200-pale-blue);
    }
     
    thead {
      display: block;
    }
  
    tbody {
      display: block;
      height: 17.8rem;
      overflow: auto;
      scrollbar-width: thin;
    }
  }
.library-container {
  height: 50px !important;
  ion-item {
    width: 100% !important;
  }
  @extend .inp-container;
}
.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-height: 300px;
  table {
    width: 100%;
  }
  th {
    width: 18%;
    min-width: 10rem;
    text-align: left;
    padding: 10px;
    color: var(--black);
    font-size: 11px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: 0.3px;
    background: var(--g-200-pale-blue);
  }
  td {
    width: 18%;
    text-align: left;
    padding: 10px;
    color: #606060;
    font-size: 11px;
    font-family: var(--font-family-semi-bold);
    letter-spacing: 0.3px;
    background: var(--g-200-pale-blue);
    border-bottom: 1px solid var(--light-gray);
  }
  td:first-child {
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
  }
}
.create-sales-org-btn {
  @extend .app-large-btn;
  width: 280px !important;
  margin-right: 16px;
}

.hide {
  display: none;
}

.show {
  display: block
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

ion-toast.custom-toast {
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --height: 70px;
  font-family: var(--font-family-semi-bold);
}

ion-toast.custom-toast::part(icon) {
  width: 36px;
  height: 36px;
}

ion-toast.error-toast {
  --background: var(--error-background);
  --color: var(--error-text);
  --border-color: var(--error-text);;
  --border-width: 1px;
  --border-style: solid;
}

ion-toast.warning-toast {
  --background: var(--warning-background);
  --color: var(--warning-text);
  --border-color: var(--warning-text);
  --border-width: 1px;
  --border-style: solid;
}

ion-toast.success-toast {
  --background: var(--success-background);
  --color: var(--success-text);
  --border-color: var(--success-text);
  --border-width: 1px;
  --border-style: solid;
}

ion-toast.custom-toast::part(message) {
  font-style: var(--font-family-semi-bold);
  font-size: var(--font-size-16);
}

.card-container-block {
  font-family: var(--font-family-regular);
  margin-left: 122px;
  margin-right: 14px;
  background-color: #d7ebff;
  padding: 0px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 45px;

  .tabs {
  display: flex;
  gap: 10px;

  .tab-option {
      font-family: var(--font-family-semi-bold);
      color: var(--p-800-midnight-blue);
      padding: 10px;
      border-bottom: 5px solid var(--grey);
      font-size: var(--font-size-14);
      cursor: pointer;
      transition: border-color 0.5s ease;
      letter-spacing: 0px;

      &.active {
      border-color: var(--p-800-midnight-blue);
      }
  }
  }
}


.tabs-block {
  margin-left: 112px;
}

.padding-left-5
{
  padding-left:5px!important;
}
.grid-wrapper-height-56 {
  height: calc(100vh - 44vh) !important;
}
.grid-wrapper-height-40 {
  height: calc(100vh - 60vh) !important;
}

.grid-wrapper-height-30 {
  height: calc(100vh - 70vh) !important;
}

.grid-wrapper-height-25 {
  height: calc(100vh - 75vh) !important;
}

.head-block {
  margin-left: 120px;
  color: var(--p-800-midnight-blue);
}

.center-align-content {
  align-content: center;
  font-family: var(--font-family-semi-bold);
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-12 {
  margin: 12px;
}
.gridDisplayStatus{
  height: 100px !important;
  padding: 15px;
  margin-left: 5px;
  margin-right: 7px;
}

.medium-alert-modal::part(content) {
  background-color: transparent !important;
  width: 75%;
  height: 65%;
  border-radius: 10px;
  left: calc(887px - var(--ion-safe-area-right, 10px));
  background: transparent;
  box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
  border: 1.5px solid var(--white);
  opacity: 1;
  backdrop-filter: blur(29px);
  -webkit-backdrop-filter: blur(29px);
}

.margin-top-12{
  margin-top:10px;
}

.padding-top-25 {
  padding-top:25px;
}

.float-left{
  float: left;
}
.app-title {
  margin: 0px 4px;
}

.padding-5 {
  padding: 0 5px;
  margin-left: 15px;
}

.center-align {
  align-items: center;
}
.initial-hazard-margin
{
  margin-left: 6%;
  margin-right: 2%;
}
// To display metrics card on project, offer and order page
.module-metrics-container {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.module-metrics-card {
  margin-left: 132px;
  margin-right: 20px;
  ion-card-subtitle {
    color: var(--p-800-midnight-blue);
    font-size: var(--font-size-16);
    font-family: var(--font-family-semi-bold) !important;
  }
}
.module-left-margin {
  margin: 8px 0px 0px 12px;
}
.module-metrics-container ion-card {
  flex: 0 0 200px;
  min-width: 200px;
  margin-bottom: 16px;
  --background: var(--white);
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
  border-radius: 4px;
  overflow: visible;
  ion-card-subtitle {
    color: var(--black);
    font-size: var(--font-size-14);
    letter-spacing: 1px;
    text-align: center !important;
  }
  ion-card-content {
    color: var(--p-800-midnight-blue);
    font-size: var(--font-size-24);
    font-family: var(--font-family-bold) !important;
    letter-spacing: 1px;
    text-align: center;
  }
}
.add-library-machine {
  width: 230px !important;
}

.pointer-cursor {
  cursor: pointer !important;
}