// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Montserrat', sans-serif !important;
  --font-family-regular: 'Montserrat', sans-serif; // Regular font
  --font-family-semi-bold: "Montserrat-semi-bold", sans-serif; // Semibold font
  --font-family-bold: "Montserrat-bold", sans-serif; // Bold font
  --font-family-extra-bold: "Montserrat-extra-bold", sans-serif; // Extra Bold font
  --font-NeueHaasDisplayBold: "NeueHaasDisplayBold", sans-serif;
  --font-NeueHaasDisplayMedium: "NeueHaasDisplayMedium", sans-serif;
  --font-neueHaasDisplayLight: "NeueHaasDisplayLight", sans-serif;

  /** font sizes **/
  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-11: 11px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-28: 28px;
  --font-size-32: 32px;
  --font-size-30: 30px;
  --font-size-34: 34px;
  --font-size-48: 48px;

  /** color codes **/
  --app-background-color: linear-gradient(to right,
      #c3d9f6,
      #c8dcf6,
      #cee0f6,
      #d3e3f6,
      #d9e6f6,
      #dce7f6,
      #dee8f6,
      #e1e9f6,
      #e2e9f6,
      #e2e8f6,
      #e3e8f6,
      #e4e7f6);
  --p-800-midnight-blue: #083a81;
  --g-500-slate-gray: #454b57;
  --g-100-powder-blue: #d1e1f6;
  --white: #ffffff;
  --black: #000000;
  --medium-grey: #4d4d4d;
  --grey: #7c7c7c;
  --light-midnight-blue: #105196;
  --light-blue: #e6edf8;
  --green: #71eb8d;
  --mustard: #f2db7c;
  --light-grey: rgba(234, 245, 251, 0.4);
  --g-100-dark-transparent: #0000001a;
  --red: #fe0000;
  --blue-gradient: transparent linear-gradient(251deg, #ffffff94 0%, #ffffff94 52%, #ffffff94 100%) 0% 0% no-repeat padding-box;
  --sky-blue: #00a4e0;
  --light-gray: #cccccc;
  --dark-grey: #4d4d4d;
  --g-200-semi-transparent-black: #0000002c;
  --g-100-pale-blue: #e3f0fb;
  --g-200-pale-blue: #e6eef8;
  --cloudy-azure: #d9e8f4;
  --light-blue: #b0bfe4;
  --y-200-light-yellow: #EDE5A3;
  --p-200-light-peach-pink: #FFBBA5;
  --g-200-light-green: #AEECB0;
  --y-600-dark-mustard: #7D6A06;
  --o-500-dark-orange: #9A4100;
  --g-700-dark-green: #06611F;
  --mist: #e5e9f2;
  --soft-blue:#DFEEF8;
  --powder-blue: #BFDAF4;
  --progress-bar:#4AA611;
  --pale-blue:#E1E9F6;
  --pale-light-blue:#BDD1E7;
  --golden-sand: #d4b650;
  --light-ivory: #F6F6DB;
  --side-menu-background: #d7ebff;
  /** primary **/
  --ion-color-primary: #083a81;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #71eb8d;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --error-text: #F50000;
  --error-background: #FFBFBF;
  --warning-text: #B18000;
  --warning-background: #FEF7BC;
  --success-text: #148D2C;
  --success-background: #C9FFCB;
}

// header part to remove bottom line shadow //
ion-header {
  position: static !important;
}

.pickhazard-alert-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  &::part(content) {
    width: 80%;
    background: transparent linear-gradient(251deg, #e4e7f6 0%, #e0eaf6 52%, #c3d9f6 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 16px 20px #0000001a;
    border: 1.5px solid #ffffff;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
  }
}

.mango-alert-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  &::part(content) {
    width: 60%;
    height: 70%;
    background: transparent;
    box-shadow: 0px 16px 20px #0000001a;
    border: 1.5px solid #ffffff;
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
  }
}

.small-alert-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  &::part(content) {
    background-color: transparent !important;
    width: 40%;
    height: 35%;
    border-radius: 10px;
    left: calc(887px - var(--ion-safe-area-right, 10px));
    background: transparent;
    box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
    border: 1.5px solid var(--white);
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
  }
}

.medium-alert-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  &::part(content) {
    background-color: transparent !important;
    width: 50%;
    height: 45%;
    border-radius: 10px;
    left: calc(887px - var(--ion-safe-area-right, 10px));
    background: transparent;
    box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
    border: 1.5px solid var(--white);
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
  }
}

.mango-dashboard-customization-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;

  &::part(content) {
    width: 1078px;
    height: 556px;
    box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
    border: 1.5px solid var(--white);
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
    background-color: transparent;
  }
}


.upload-machine-customization-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;

  &::part(content) {
    width: 55%;
    height: 65%;
    box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
    border: 1.5px solid var(--white);
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
    background-color: transparent;
  }
}

ion-button {
  text-transform: none;
}

ion-checkbox {
  --border-color: #7d98bf;
  --background: #ebefff;
  --background-checked: #ebefff;
  --checkmark-color: #083a81;
  --border-color-checked: #7d98bf;
}

.disabled-checkbox {
  --border-color: #9b9b9b;
  --background: #ebefff;
  --background-checked: #ebefff;
  --checkmark-color: #9b9b9b;
  --border-color-checked: #9b9b9b;
}

ion-reorder-group {
  .reorder-selected {
    box-shadow: none !important;
  }
}

.mango-upload-customization-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;

  &::part(content) {
    width: 1078px;
    height: 556px;
    box-shadow: 0px 16px 20px var(--g-100-dark-transparent);
    border: 1.5px solid var(--white);
    border-radius: 10px;
    opacity: 1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
    background-color: transparent;
  }
}
 

.large-alert-modal {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  transform: scale(1); /* Ensure it's scaling */
	transform-origin: center center;
  &::part(content) {
    width: 70%;
    height: 60%;
    background: transparent;
    box-shadow: 0px 16px 20px #0000001a;
    border: 1.5px solid #ffffff;
    border-radius: 10px;
    opacity: 1;
  }
}